import React, { Component } from 'react';
import flag from '../images/flag.jpg';
import topLogo from '../images/toplogo.png';
import Footer from '../Footer/Footer';
import { SocialIcon } from 'react-social-icons';

class Confirmation extends Component {
	render() {
		return (
			<div className="formPage">
				<img src={topLogo} className="topLogo" alt="Make America Purple" width="80%" />
				<img src={flag} className="flag" alt=""></img>
				<div className="mainSection">
					<div>
						<h3>Thank you!</h3>
						<p>Please check your inbox for a confirmation email from hello@onecommune.com.
							We will connect you with your conversation partner via email as soon as there&rsquo;s a match.</p>
						<h4>Share Make America Purple</h4>
						<p>Each authentic human connection is another stitch in the fabric of our society.
							Spread makeamericapurple.org with your friends, followers, and especially with those who have different viewpoints.</p>
						<SocialIcon target="_blank" url="https://www.facebook.com/sharer/sharer.php?u=makeamericapurple.org" />
						<SocialIcon target="_blank" url="https://twitter.com/intent/tweet?text=You%20can%20help%20stitch%20the%20fabric%20of%20society%20back%20together%20one%20human-to-human%20conversation%20at%20a%20time.%20Get%20matched%20with%20a%20conversation%20partner%20from%20across%20the%20political%20divide%20at%20MakeAmericaPurple.org.%20%23makeamericapurple" />
						<SocialIcon target="_blank" url="https://www.dropbox.com/sh/x0kwnrf344738hx/AABEilGICgesKUR5-RSuxdp8a?dl=0" />
						<SocialIcon target="_blank" url="mailto:?subject=It%20is%20hard%20to%20hate%20up%20close&body=I%20thought%20you%20might%20be%20interested%20in%20MakeAmericaPurple.org.%20Enter%20your%20email%20and%20get%20matched%20with%20a%20conversation%20partner%20from%20across%20the%20political%20divide.%20Together%20we%20can%20stitch%20the%20fabric%20of%20our%20society%20back%20together%20%E2%80%94%20one%20human-to-human%20conversation%20at%20a%20time.%0A%0AJoin%20me%20at%20MakeAmericaPurple.org.%20%0A" />
						<p className="smallText">Make America Purple is a project of Commune, a course platform for personal and societal wellbeing. Learn more at <a href="https://ww2.onecommune.com" target="_blank">onecommune.com</a>.</p>
						</div>
					<Footer />
				</div>
			</div>
		);
	}
}

export default Confirmation;