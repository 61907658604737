import React, { Component } from 'react';
import flag from '../images/flag.jpg';
import topLogo from '../images/toplogo.png';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

class SignupForm extends Component {
	state = {
		formValid: false,
		firstName: {
			value: '',
			validation: {
				required: true
			},
			valid: false,
			className: 'validInput'
		},
		email: {
			value: '',
			validation: {
				required: true,
				email: true
			},
			valid: false,
			className: 'validInput'
		},
		identify: {
			value: '',
			validation: {
				required: true
			},
			valid: false,
			className: 'validRadio'
		},
		agree: {
			value: false,
			validation: {
				mustBeTrue: true
			},
			valid: false,
			className: 'validCheckbox'
		}
	};

	checkValidation = (value, rules) => {
		let isValid = true;

		if (rules.required) {
			isValid = value.trim() !== '' && isValid;
		}

		if (rules.mustBeTrue) {
			isValid = value && isValid;
		}

		if (rules.email) {
			isValid = (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) && isValid;
		}

		return isValid;
	}

	updateFieldStyling = (fieldName) => {
		if (!fieldName || fieldName === 'firstName') {
			this.setState(prevState => {
				let firstName = Object.assign({}, prevState.firstName);
				firstName.className = firstName.valid ? 'validInput' : 'invalidInput';
				return { firstName };
			});
		}
		if (!fieldName || fieldName === 'email') {
			this.setState(prevState => {
				let email = Object.assign({}, prevState.email);
				email.className = email.valid ? 'validInput' : 'invalidInput';
				return { email };
			});
		}
		if (!fieldName || fieldName === 'identify') {
			this.setState(prevState => {
				let identify = Object.assign({}, prevState.identify);
				identify.className = identify.valid ? 'validRadio' : 'invalidRadio';
				return { identify };
			});
		}
		if (!fieldName || fieldName === 'agree') {
			this.setState(prevState => {
				let agree = Object.assign({}, prevState.agree);
				agree.className = agree.valid ? 'validCheckbox' : 'invalidCheckbox';
				return { agree };
			});
		}
	}

	handleSubmit = event => {
		event.preventDefault();
		const formValid = (this.state.firstName.valid && this.state.email.valid && this.state.identify.valid && this.state.agree.valid);
		console.log(formValid);
		if (formValid) {
			const request = {
				email: this.state.email.value,
				first_name: this.state.firstName.value,
				identify: this.state.identify.value
			};
			axios.post('https://communeapi.com/makeamericapurple/signup', request)
				.then(response => {
					window.location.href = '/thankyou';
				})
				.catch(err => {
				
				});
		} else {
			// update the validation styling for the fields
			this.updateFieldStyling();
		}
	}

	handleChange = event => {
		switch (event.target.name) {
			case 'firstName':
				this.setState(prevState => {
					let firstName = Object.assign({}, prevState.firstName);
					firstName.value = event.target.value;
					firstName.valid = this.checkValidation(firstName.value, firstName.validation);
					return { firstName };
				});
				this.updateFieldStyling('firstName');
				break;
			case 'email':
				this.setState(prevState => {
					let email = Object.assign({}, prevState.email);
					email.value = event.target.value;
					email.valid = this.checkValidation(email.value, email.validation);
					return { email };
				});
				this.updateFieldStyling('email');
				break;
			case 'identify':
				this.setState(prevState => {
					let identify = Object.assign({}, prevState.identify);
					identify.value = event.target.value;
					identify.valid = true; // this.checkValidation(identify.value, identify.validation);
					return { identify };
				});
				this.updateFieldStyling('identify');
				break;
			case 'agree':
				this.setState(prevState => {
					let agree = Object.assign({}, prevState.agree);
					agree.value = event.target.checked;
					agree.valid = this.checkValidation(agree.value, agree.validation);
					return { agree };
				});
				this.updateFieldStyling('agree');
				break;
			default:
		}
		this.setState({ formValid: (this.state.firstName.valid && this.state.email.valid && this.state.identify.valid && this.state.agree.valid) });
	}

	render() {
		return (
			<div className="formPage">
				<img src={topLogo} className="topLogo" alt="Make America Purple" width="80%" />
				<img src={flag} className="flag" alt=""></img>
				<div className="mainSection">
					<div>
						<h3>It&rsquo;s hard to hate up close.</h3>
						<p>Enter your email to connect with someone of a different political perspective.
							One-on-one conversations help us discover our common ground and shared humanity.</p>
						<form onSubmit={this.handleSubmit}>
							<div className={this.state.identify.className}>
								<h4>I identify as&hellip;</h4>
								<div className="identifySection">
									<label className="radio radio-gradient">
										<span className="radio__label">Liberal </span>
										<span className="radio__input">
											<input type="radio" value="l" name="identify" onChange={this.handleChange} />
											<span className="radio__control"></span>
										</span>
									</label>

									<label className="radio radio-before">
										<span className="radio__label">Conservative </span>
										<span className="radio__input">
											<input type="radio" value="c" name="identify" onChange={this.handleChange} />
											<span className="radio__control"></span>
										</span>
									</label>
								</div>
							</div>
							<input type="text" name="firstName" placeholder="First name" className={this.state.firstName.className} onChange={this.handleChange}></input><br />
							<input type="text" name="email" placeholder="Email address" className={this.state.email.className} onChange={this.handleChange}></input><br />
							<div className="agreeSection">
								<input type="checkbox" name="agree" className={this.state.agree.className} onChange={this.handleChange} /><div>I agree to share my email address with my conversation partner</div>
							</div>
							<button type="submit">Connect Me</button>
						</form>
						<p className="smallText">By signing up, you will receive an email connecting you with your conversation partner.
								We will also send you conversation guidelines and tips.</p>
						<p className="smallText">This is a project of Commune, a course platform for personal and societal wellbeing.
							Learn more at <a href="https://ww2.onecommune.com" target="_blank">onecommune.com</a>.</p>
					</div>
					<Footer />
				</div>
			</div>
		);
	}
}

export default SignupForm;