import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import './App.scss';
import mapShareBanner from './images/map_share_banner.jpg';
import Confirmation from './Confirmation/Confirmation';
import SignupForm from './SignupForm/SignupForm';


class App extends Component {
	render() {
		return (
			<div className="App">
				<MetaTags>
					<meta name="og:title" content="Make America Purple" />
					<meta name="og:description" content="Do you find it challenging to understand &ldquo;the other side&rdquo;? Are you frustrated with political polarization? Get matched with a conversation partner from across the divide at MakeAmericaPurple.org. Together we can stitch the fabric of our society back together — one human-to-human conversation at a time." />
					<meta name="og:image" content={mapShareBanner} />
				</MetaTags>
				<Route path="/" exact render={() => <SignupForm />} />
				<Route path="/thankyou" exact render={() => <Confirmation />} />
			</div>
		);
	}
}

export default App;
