import React, { Component } from 'react';
import ReactDom from 'react-dom';
import background from '../images/background.jpg';
import topLogo from '../images/toplogo.png';
import { SocialIcon } from 'react-social-icons';

class Footer extends Component {
	render() {
		return (
			<footer>
				<nav>
					<ul>
						<li><a href="mailto:support@onecommune.com">Contact Us</a></li>
						<li><a href="https://ww2.onecommune.com/terms/" target="_blank">Terms</a></li>
						<li><a href="https://ww2.onecommune.com/privacy/" target="_blank">Privacy</a></li>
					</ul>
				</nav>
			</footer>
		);
	}
}

export default Footer;